#root .ql-editor {
  font-size: 16px;
}

#root .ql-snow.ql-toolbar {
  height: 30px;
  padding: 1.4px;
  border-bottom: 0px;
}

#root .ql-tooltip {
  z-index: 3;
}
#root .ql-toolbar {
  height: 27px;
}

#root .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc;
}
